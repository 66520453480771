import React from 'react'
import { Layout, Posts, SEO } from '../components'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'

const TagTemplate = props => {
  const {
    data: {
      allMdx: { nodes: posts }
    },
  } = props
  const {
    pageContext: { tag, tagWithHyphen } // uniqueTagArr
  } = props

  return (
    <Layout>
      <SEO title={tag} />
      <Wrapper>
        <h3 className="tags-title">
          <Link to={`/tags/`} className="link">
            태그
          </Link>
          <span> » </span>
          <Link to={`/tag/${tagWithHyphen}/`} className="link">
            {tag}
          </Link>
        </h3>
        <div className="tags-center">
          {posts.length ? <Posts posts={posts} /> : null}
        </div>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query GetContentByTag($tag: String) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          tags: { eq: $tag }
          published: { eq: true }
        }
      }
    ) {
      nodes {
        excerpt(pruneLength: 100, truncate: true)
        frontmatter {
          title
          author
          division
          category
          subcategory
          tags
          date(formatString: "YYYY-MM-DD")
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        id
        timeToRead
      }
    }
  }
`

const Wrapper = styled.section`
  width: 85vw;
  max-width: var(--max-width);
  margin: 8rem auto;
  .tags-title {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--clr-content);
    font-size: 1.25rem;
    margin-bottom: 2rem;
    a {
      cursor: pointer;
      color: var(--clr-content);
    }
    :target {
      background-color: var(--clr-hyperlink);
      color: var(--clr-hyperlink);
    }
    span {
      font-weight: 300;
    }
  }
  .tags-center {
    margin-top: -6rem;
  }
  @media screen and (min-width: 700px) {
    width: 92vw;
  }
  @media screen and (min-width: 1170px) {
    .tags-center {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 2rem;
      margin-top: -10rem;
    }
  }
`
export default TagTemplate
